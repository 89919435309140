import { disclosure } from '../utils/disclosure';
import { addJSClass } from '../utils/progressive-enhancement';
import { getEventElement } from '../utils/event-handlers';
import { translate } from '../utils/translation';
import { accountForScrollbar } from '../utils/handle-scrollbar';

export class BranchRotator {
    private body: HTMLBodyElement | null;
    private siteHeader: HTMLElement | null;
    private branchRotator: HTMLElement | null;
    private toggleDropdownButtons: NodeListOf<HTMLButtonElement>;
    private dropdowns: NodeListOf<HTMLDivElement>;
    private slides: NodeListOf<HTMLUListElement>;
    private sliderButton: HTMLElement | null | undefined;

    constructor(branchRotator: HTMLElement) {
        this.body = document.querySelector('body');
        this.siteHeader = document.getElementById('header');
        this.branchRotator = branchRotator;
        this.toggleDropdownButtons = branchRotator.querySelectorAll(
            '[data-id=toggle-branch-rotator-dropdown]'
        );
        this.dropdowns = branchRotator.querySelectorAll(
            '.gw-branch-rotator__dropdown'
        );
        this.slides = branchRotator.querySelectorAll('.gw-slider__slide');
        this.sliderButton = branchRotator?.querySelector(
            '[data-button-type=next]'
        ) as HTMLButtonElement;

        this.init();
    }

    public static start(): any {
        const branchRotator = document.querySelector(
            '.gw-branch-rotator'
        ) as HTMLElement;

        if (branchRotator) {
            addJSClass(branchRotator);

            const instance = new BranchRotator(branchRotator);

            return instance;
        }
    }

    private init(): void {
        this.initOverlayPosition();
        this.initDropdownSize();
        this.initDropdownButtons();
        this.handleWindowResize();
        this.handleWindowScroll();
        this.handleStickyHeader();
        this.handleSliderButtonClick();
        this.handleOpenClosedMessage();
        this.initHotline();
        this.initStockVehicleDealer();
    }

    // Header overlay position on page load.
    private initOverlayPosition(): void {
        if (this.siteHeader && this.branchRotator) {
            const overlay: HTMLElement | null = this.siteHeader.querySelector(
                '.gw-layout__header__overlay'
            );
            let offset =
                this.branchRotator.getBoundingClientRect().top +
                this.branchRotator.getBoundingClientRect().height;

            const elemHtml = document.documentElement;
            const documentWidth = elemHtml.clientWidth;
            const windowWidth = window.innerWidth;
            const scrollWidth = windowWidth - documentWidth;

            if (this.siteHeader.classList.contains('is-js-sticky')) {
                offset = this.siteHeader.getBoundingClientRect().height;
            }

            if (overlay) {
                overlay.style.insetBlockStart = `${offset}px`;
                overlay.style.insetInlineEnd = `${scrollWidth}px`;
            }

            [...(this.dropdowns as any)].map((dropdown) => {
                dropdown.style.insetBlockStart = `${offset}px`;
                dropdown.style.insetInlineEnd = `${scrollWidth}px`;
                return true;
            });

            // Window has scrolled. Reposition the dropdown and calculate overlay height.
            if (
                this.siteHeader.classList.contains('has-window-scrolled') &&
                !this.siteHeader.classList.contains('is-js-sticky')
            ) {
                offset += window.scrollY;

                if (overlay) {
                    overlay.style.insetBlockStart = `${offset}px`;
                    overlay.style.insetInlineEnd = `${scrollWidth}px`;
                }

                [...(this.dropdowns as any)].map((dropdown) => {
                    dropdown.style.insetBlockStart = `${
                        offset - window.scrollY
                    }px`;
                    dropdown.style.insetInlineEnd = `${scrollWidth}px`;
                    return true;
                });
            }
        }
    }

    // Dropdown size.
    private initDropdownSize(): void {
        [...(this.dropdowns as any)].map((dropdown) => {
            dropdown.style.width = `${
                this.branchRotator?.getBoundingClientRect().width
            }px`;
            return true;
        });
    }

    // Dropdown buttons.
    private initDropdownButtons(): void {
        if (this.toggleDropdownButtons) {
            [...(this.toggleDropdownButtons as any)].map((button: any) => {
                const content = button
                    .closest('.gw-slider__slide')
                    .querySelector('.gw-branch-rotator__dropdown');
                const buttonLabel = button.querySelector('.sr-only');

                const svgIconShowTitle = button.querySelectorAll('title')[0];
                const svgIconHideTitle = button.querySelectorAll('title')[1];

                // Apply translations.
                const buttonShowText = translate(
                    'BranchRotatorShowDepts',
                    'Show all departments'
                );
                const buttonHideText = translate(
                    'BranchRotatorHideDepts',
                    'Hide all departments'
                );
                buttonLabel.textContent = buttonShowText;
                svgIconShowTitle.textContent = buttonShowText;
                svgIconHideTitle.textContent = buttonHideText;

                disclosure({
                    button,
                    content,
                });

                button.addEventListener('click', (e: MouseEvent) => {
                    const target = getEventElement(
                        e.currentTarget
                    ) as HTMLButtonElement;
                    this.handleDropdownButtonClick(
                        target,
                        buttonShowText,
                        buttonHideText
                    );
                });

                return true;
            });
        }
    }

    // Handle window resize.
    private handleWindowResize(): void {
        const resizeCallback = (): void => {
            if (this.branchRotator && this.dropdowns) {
                this.initDropdownSize();
            }
        };
        window.setTimeout(() => {
            // Set dropdown size on window resize.
            window.addEventListener('resize', resizeCallback);
            // Also set dropdown position on window resize.
            window.addEventListener('resize', (): void =>
                this.initOverlayPosition()
            );
        }, 500);
    }

    // Handle window scroll.
    private handleWindowScroll(): void {
        const windowScrollCallback = (): void => {
            if (this.branchRotator && this.dropdowns) {
                if (window.scrollY > 0) {
                    this.siteHeader?.classList.add('has-window-scrolled');
                } else {
                    this.siteHeader?.classList.remove('has-window-scrolled');
                }
            }
        };
        window.setTimeout(() => {
            window.addEventListener('scroll', windowScrollCallback);
        }, 500);
    }

    // Handle sticky header.
    private handleStickyHeader(): void {
        // Sticky header layout causes branch rotator width to change because of the different CSS 'grid-template-columns'. Ensure that slide is fully visible by triggering 'next' button click when window scroll has stopped.
        let scrollTimer: ReturnType<typeof setTimeout>;
        let sliderWidth = 0;
        const windowScrollCallback2 = (): void => {
            clearTimeout(scrollTimer);
            scrollTimer = setTimeout(() => {
                if (
                    this.branchRotator &&
                    this.branchRotator.clientWidth !== sliderWidth
                ) {
                    sliderWidth = this.branchRotator.clientWidth;
                }
            }, 250);
        };
        window.addEventListener('scroll', windowScrollCallback2);
    }

    // Handle dropdown toggle button click.
    private handleDropdownButtonClick(
        target: HTMLButtonElement,
        buttonShowText: string,
        buttonHideText: string
    ): void {
        const targetAriaExpanded = target.getAttribute('aria-expanded');
        const buttonLabel = target.querySelector('.sr-only');

        const elemHtml = document.documentElement;
        const documentWidth = elemHtml.clientWidth;
        const windowWidth = window.innerWidth;
        const scrollWidth = windowWidth - documentWidth;

        const dropdown = document.querySelector(
            '.gw-branch-rotator__dropdown'
        ) as HTMLElement;

        const overlay = document.querySelector(
            '.gw-layout__header__overlay'
        ) as HTMLElement;

        const modal = document.querySelector(
            '.gw-modal--full-width'
        ) as HTMLElement;

        targetAriaExpanded &&
            target.setAttribute('aria-expanded', targetAriaExpanded);

        if (target.getAttribute('aria-expanded') === 'true') {
            // Stop page jump when scrollbar is hidden.
            accountForScrollbar(true);

            buttonLabel && (buttonLabel.textContent = buttonHideText);
            this.body?.classList.add('has-open-modal');
            this.siteHeader?.classList.add('display-nav');
            this.siteHeader?.classList.add('has-open-overlay');
            this.siteHeader?.classList.add('has-open-overlay--alt');

            if (dropdown) dropdown.style.insetInlineEnd = `${scrollWidth}px`;
            if (overlay) overlay.style.insetInlineEnd = `${scrollWidth}px`;
            if (modal) modal.style.insetInlineEnd = `${scrollWidth}px`;

            this.siteHeader && this.branchRotator && this.initOverlayPosition();
        } else {
            // Stop page jump when scrollbar is shown.
            accountForScrollbar(false);

            buttonLabel && (buttonLabel.textContent = buttonShowText);
            this.body?.classList.remove('has-open-modal');
            this.siteHeader?.classList.remove('has-open-overlay');
            this.siteHeader?.classList.remove('has-open-overlay--alt');

            if (dropdown) dropdown.style.insetInlineEnd = `0px`;
            if (overlay) overlay.style.insetInlineEnd = `0px`;
            if (modal) modal.style.insetInlineEnd = `0px`;
        }
    }

    // Handle slider button click.
    private handleSliderButtonClick(): void {
        if (this.sliderButton) {
            this.sliderButton.addEventListener('click', () => {
                [...(this.toggleDropdownButtons as any)].map((button) => {
                    // If dropdown is showing, close it again.
                    if (button.getAttribute('aria-expanded') === 'true') {
                        button.click();
                    }

                    // Handle hotline button styling.
                    this.initHotline();

                    return true;
                });
            });
        }
    }

    // Handles whether OPEN or CLOSED text shows up in contact details section
    private handleOpenClosedMessage(): void {
        // Get current date and time (this is used in the below functions).
        const date = new Date();

        // helper function to translate open/close messages to foreign words supplied in branch rotator data.
        const translateMessage = (message: string) => {
            return this.branchRotator!.getAttribute(`data-${message}-value`);
        };

        // function to display opening/closing time data
        const displayOpenCloseData = (data: any, nodeToDisplayText: any) => {
            // define empty string for display data (data will be appended to this string);
            let dataToDisplay = '';

            // checks data to determines whether the data is being used for the main branch (reception) or a department of the main branch.
            const branchOrDepartment =
                data.querySelector('[data-branch-dayofweek]') === null
                    ? 'department'
                    : 'branch';

            // returns blank string if all opening time fields are left blank
            if (
                [...data.getElementsByTagName('span')]
                    .map(function (d) {
                        return (
                            d.getAttribute(
                                `data-${branchOrDepartment}-opentime`
                            ) === ''
                        );
                    })
                    .every(Boolean)
            )
                return dataToDisplay;

            // helper function to find the nearest possible day when the service is open, returns string value of next open day + time
            const getNextOpenDay = (
                openingClosingData: any,
                dataToDisplay: any
            ) => {
                // get array of opening times, one <span> for every day of the week
                const openingTimesArray = [].slice.call(
                    openingClosingData!.getElementsByTagName('span')
                );
                // slice and re-combine array so that the first element starts with whatever tomorrow's day is.
                const nextFullWeek = openingTimesArray
                    .slice(date.getDay() + 1)
                    .concat(
                        openingTimesArray.slice(0, date.getDay() + 1)
                    ) as any;
                // loop through next full week, find the next open day and return the opening time in the branch
                for (let i = 0; i < nextFullWeek.length; i++) {
                    if (
                        nextFullWeek[i].getAttribute(
                            `data-${branchOrDepartment}-closed`
                        ) === 'False'
                    ) {
                        dataToDisplay +=
                            // displays 'Opens' or equivalent translation
                            ` - ${translateMessage('opens')} ` +
                            // displays the next day on which the branch/department is open
                            `${new Map([
                                ['Sunday', translateMessage('sunday')],
                                ['Monday', translateMessage('monday')],
                                ['Tuesday', translateMessage('tuesday')],
                                ['Wednesday', translateMessage('wednesday')],
                                ['Thursday', translateMessage('thursday')],
                                ['Friday', translateMessage('friday')],
                                ['Saturday', translateMessage('saturday')],
                            ]).get(
                                nextFullWeek[i].getAttribute(
                                    `data-${branchOrDepartment}-dayofweek`
                                )
                            )}` +
                            ' ' +
                            // displays the opening time for the aforementioned day
                            `${nextFullWeek[i]
                                .getAttribute(
                                    `data-${branchOrDepartment}-opentime`
                                )
                                .slice(0, 5)}`;
                        return dataToDisplay;
                    }
                }
            };

            // opening/closing date for the current day
            const currentDayData = data.querySelector(
                `[data-${branchOrDepartment}-dayofweek = ${
                    [
                        'Sunday',
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                        'Saturday',
                    ][date.getDay()]
                }]`
            ) as Element;

            // check if the branch/department is closed for today
            if (
                currentDayData.getAttribute(
                    `data-${branchOrDepartment}-closed`
                ) === 'True'
            ) {
                // if so, display 'Closed' (or equivalent translation) and the next open day + time
                dataToDisplay = getNextOpenDay(
                    data,
                    (dataToDisplay += translateMessage('closed'))
                );
            } else {
                // Get the current time right now in string form
                let currentTimeString = '';
                if (date.getHours() < 10) currentTimeString += '0';
                currentTimeString +=
                    date.getHours().toString() +
                    ':' +
                    date.getMinutes().toString() +
                    ':' +
                    date.getSeconds().toString();

                // Get today's opening time in string form
                const openTimeString = currentDayData.getAttribute(
                    `data-${branchOrDepartment}-opentime`
                ) as string;

                // Get today's closing time in string form
                const closeTimeString = currentDayData.getAttribute(
                    `data-${branchOrDepartment}-closetime`
                ) as string;

                // Check if current time falls in between today's opening/closing times
                if (
                    currentTimeString >= openTimeString &&
                    currentTimeString < closeTimeString
                ) {
                    // If yes, display 'Open' along with today's closing time
                    dataToDisplay += `${translateMessage(
                        'open'
                    )} - ${translateMessage('closes')} ${closeTimeString.slice(
                        0,
                        2
                    )}:${closeTimeString.slice(3, 5)}`;
                } else {
                    // If no, display 'Closed' along with the next opening time this week.
                    dataToDisplay += translateMessage('closed');
                    if (currentTimeString < openTimeString) {
                        dataToDisplay +=
                            ` - ${translateMessage('opens')} ` +
                            openTimeString.slice(0, 2) +
                            ':' +
                            openTimeString.slice(3, 5);
                    } else if (currentTimeString >= closeTimeString) {
                        dataToDisplay = getNextOpenDay(data, dataToDisplay);
                    }
                }
            }

            // if this data is for the department, wrap it in brackets (as per design)
            if (branchOrDepartment == 'department') {
                dataToDisplay = '(' + dataToDisplay + ')';
            }

            // inject data into the frontend as text element.
            nodeToDisplayText.insertBefore(
                document.createTextNode(dataToDisplay),
                nodeToDisplayText.children[0]
            );
        };

        // MAIN CODE (calls helper functions above)
        // Get list of all branches.
        const branchList =
            this.branchRotator?.querySelectorAll('.gw-slider__slide');

        // Loop through each branch.
        branchList!.forEach(function (b) {
            // get full set of reception opening data for the branch
            const branchData = b.querySelector(
                '.gw-branch-rotator__open-close-data--branch'
            );
            // check data exists on branch and has data child nodes, if so then display the data
            if (branchData) {
                // get node in which the open/close text will be displayed
                const nodeToDisplayText = b.querySelector(
                    '.gw-branch-rotator__text--open-close'
                ) as Element;
                // call data to be displayed in the node
                if (
                    nodeToDisplayText &&
                    branchData.getElementsByTagName('span').length > 0
                ) {
                    displayOpenCloseData(branchData, nodeToDisplayText);
                }
            }

            // get full list of department accordions for the branch
            const departmentAccordions = b.querySelectorAll(
                '.gw-branch-rotator__dropdown .gw-accordion'
            );
            // iterate through each department
            for (let i = 0; i < departmentAccordions.length; i++) {
                // check to see if open/close data occurs on department
                const departmentData = departmentAccordions[i].querySelector(
                    '.gw-branch-rotator__open-close-data--department'
                );
                // if it does, then display the data in the accordion
                if (
                    departmentData &&
                    departmentData.getElementsByTagName('span').length > 0
                ) {
                    const nodeToDisplayText = departmentAccordions[
                        i
                    ].querySelector('.gw-accordion__button__inner');
                    displayOpenCloseData(departmentData, nodeToDisplayText);
                }
            }
        });
    }

    // Hotline.
    private initHotline(): void {
        const hotline = this.branchRotator?.dataset.hotline;
        const hotlineTheme = this.branchRotator?.dataset.hotlineTheme;
        const hotlineSlide = this.branchRotator?.querySelector(
            '.gw-branch-rotator__hotline-slide'
        );

        // Toggle slider 'next' button class if hotline is visible
        const toggleButtonClass = (): void => {
            if (hotlineSlide?.classList.contains('is-visible')) {
                this.sliderButton?.classList.add(
                    'gw-branch-rotator__hotline-button'
                );
            } else {
                this.sliderButton?.classList.remove(
                    'gw-branch-rotator__hotline-button'
                );
            }
        };

        if (hotline === 'true' && hotlineTheme === 'intense') {
            // On page load.
            toggleButtonClass();

            // Listen for custom 'SliderGoTo' event when branch rotator auto slides.
            window.addEventListener('SliderGoTo', toggleButtonClass);
        }
    }

    // Anchor branch rotator to correct branch slide (e.g. on stock page).
    private initStockVehicleDealer(): void {
        if (analyticsDatalayer.vehicleInfo) {
            const branchId = analyticsDatalayer.vehicleInfo.branchId;

            if (branchId) {
                [...(this.slides as any)].map((slide, index) => {
                    const branchLink = slide.querySelector(
                        '.gw-branch-rotator__link'
                    );

                    const branchLinkId = branchLink.dataset.branch;

                    if (branchLinkId === branchId.toString()) {
                        for (let i = 0; i < index; i++) {
                            this.sliderButton?.click();
                        }
                    }
                });
            }
        }
    }
}
