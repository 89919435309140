export class FormMulti {
    private formMulti: HTMLElement;

    constructor(formMulti: HTMLElement) {
        this.formMulti = formMulti;
        this.init();
    }

    public static start(): any {
        const formMultis = document.querySelectorAll('.gw-form__select--multi');

        if (formMultis) {
            [...(formMultis as any)].map((formMulti) => {
                // check element is not part of multi-franchise advanced form so the JS doesn't overlap
                if (formMulti.closest('.gw-mf-form__advanced') == null) {
                    const instance = new FormMulti(formMulti);
                    return instance;
                }
            });
        }
    }

    private init(): void {
        this.handleFormMulti();
    }

    private handleFormMulti(): void {
        // declare current multi-select overlay from private variable
        const thisOverlay = this.formMulti;
        document.addEventListener('click', function (event) {
            function showDropdown(e: any) {
                // displays the dropdown when clicked
                const dropdownClicked = e.parentElement.querySelector(
                    '.gw-form__select--checkboxes'
                );
                dropdownClicked.classList.toggle('show');
                // puts select box into 'focus' state when clicked
                const selectBox = e.querySelector('.gw-form__select');
                selectBox.focus();
                selectBox.classList.toggle('is-open');
                // closes down any open dropdowns if a new dropdown is opened
                const allDropdowns = document.querySelectorAll(
                    '.gw-form__select--checkboxes'
                );
                allDropdowns.forEach(function (dropdown: any) {
                    if (
                        dropdown.classList.contains('show') &&
                        dropdown !== dropdownClicked
                    ) {
                        dropdown.classList.toggle('show');
                        dropdown.parentElement
                            .querySelector('.gw-form__select')
                            .classList.toggle('is-open');
                    }
                });
            }

            // declare constants for target clicked and its corresponding overlay
            const target = event.target as Element;
            const targetOverlay = target.parentElement?.parentElement;

            // check to see if clicked element is an overlay, and if it is the overlay defined in this instance
            if (
                target.classList.contains('gw-form__select--overlay') &&
                thisOverlay === targetOverlay
            ) {
                // if it passes the check, run the show dropdown function
                showDropdown(target.parentElement);
            }
        });
    }
}
