import { addJSClass } from '../utils/progressive-enhancement';

export class PriceGrid {
    private priceGrid: Element | null;

    constructor(priceGrid: HTMLElement) {
        this.priceGrid = priceGrid;
        this.init();
    }

    public static start(): any {
        const priceGrid = document.querySelector(
            '.gw-price-grid'
        ) as HTMLElement;

        if (priceGrid) {
            addJSClass(priceGrid);
            const instance = new PriceGrid(priceGrid);
            return instance;
        }
    }

    private init(): void {
        this.handleToggleButton();
    }

    private handleToggleButton(): void {
        this.priceGrid!.addEventListener('click', function (event) {
            const target = event.target as Element;

            if (target.classList.contains('gw-button--toggle')) {
                document
                    .querySelectorAll('.gw-price-card__price')
                    .forEach(function (currentValue) {
                        if (!target.classList.contains('is-selected')) {
                            currentValue.classList.toggle('hide');
                        }
                    });

                target
                    .parentElement!.querySelectorAll('.gw-button--toggle')
                    .forEach(function (currentValue) {
                        if (currentValue !== target) {
                            currentValue.classList.remove('is-selected');
                        } else {
                            currentValue.classList.add('is-selected');
                        }
                    });
            }
        });
    }
}
