import { ajaxAbortHandler, ajaxErrorHandler } from '../utils/ajax-helpers';

// TODO: Fix TS errors in this file - e.g. 'any' types?

const analyticsDatalayer: any = {};
analyticsDatalayer.consent = analyticsDatalayer.consent || {
    PerformanceEnabled: 'false',
    FunctionalityEnabled: 'false',
    TargetingEnabled: 'false',
    HasIndicatedConsent: 'false',
};

export class CookieConsent {
    private ajaxContainer: HTMLBodyElement | null;
    private showAjaxLoader: boolean;
    private ajaxTimeout: number;
    private cookieConsentOverlay: HTMLElement | null;
    private btnCookieAccept: HTMLAnchorElement | null;
    private btnCookieReject: HTMLAnchorElement | null;
    private cookieConsentForm: HTMLElement | null;
    private btnSavePreferences: HTMLButtonElement | null;

    constructor() {
        this.showAjaxLoader = false;
        this.ajaxTimeout = 10000;
        this.ajaxContainer = document.querySelector('body');
        this.cookieConsentOverlay =
            document.querySelector('.gw-cookie-consent');
        this.btnCookieAccept = document.getElementById(
            'consent-accept'
        ) as HTMLAnchorElement;
        this.btnCookieReject = document.getElementById(
            'consent-reject'
        ) as HTMLAnchorElement;
        this.cookieConsentForm = document.getElementById('cg-manage');
        this.btnSavePreferences = document.getElementsByClassName(
            'cg-store'
        )[0] as HTMLButtonElement;
    }

    static trackAnalytics(data: any): void {
        analyticsDatalayer.consent.PerformanceEnabled = `${data.PerformanceEnabled}`;
        analyticsDatalayer.consent.FunctionalityEnabled = `${data.FunctionalityEnabled}`;
        analyticsDatalayer.consent.TargetingEnabled = `${data.TargetingEnabled}`;
        analyticsDatalayer.consent.HasIndicatedConsent = `${data.HasIndicatedConsent}`;

        document.body.dispatchEvent(
            new CustomEvent('ConsentLoaded', {
                detail: { settings: analyticsDatalayer.consent },
            })
        );

        // eslint-disable-next-line no-underscore-dangle
        let _satellite: any;
        if (typeof _satellite === 'undefined') {
            // eslint-disable-next-line no-console
            console.log('Adobe Not Loaded');
            return;
        }

        _satellite.track('ConsentLoaded');
    }

    static fetchScripts(
        ajaxUrl: string,
        ajaxContainer: HTMLBodyElement | null,
        showAjaxLoader: boolean,
        ajaxTimeout: number
    ): void {
        if (ajaxContainer) {
            const ajaxLoaderContainer = ajaxContainer;
            fetch(ajaxUrl, {
                signal: ajaxAbortHandler({
                    ajaxLoaderContainer,
                    showAjaxLoader,
                    ajaxTimeout,
                }),
            })
                .then((response) => response.text())
                .then((html) => {
                    if (html !== '') {
                        const div = CookieConsent.addScriptsToDOM(html);
                        ajaxContainer.appendChild(div);
                    }
                })
                .catch((error) => {
                    ajaxErrorHandler({ error });
                });
        }
    }

    static addScriptsToDOM(html: any): HTMLDivElement {
        const div: HTMLDivElement = document.createElement('div');
        div.id = 'consent-scripts';

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        const scripts = doc.getElementsByTagName('script');

        [...(scripts as any)].map((script) => {
            const scr: HTMLScriptElement = document.createElement('script');
            scr.text = script.text;
            // Do we have a SRC attribute in Ajax response? If so, add it, and ASYNC.

            if (script.attributes && script.attributes.length > 0) {
                scr.async = true;

                for (let i = 0; i < script.attributes.length; i += 1) {
                    const attr = script.attributes[i];
                    scr.setAttribute(attr.name, attr.value);
                }
            }

            div.appendChild(scr);
            return true;
        });

        const children = doc.body.childNodes;

        [...(children as any)].map((child) => {
            if (child.nodeName.toUpperCase() !== 'SCRIPT') {
                div.appendChild(child);
            }
            return true;
        });

        return div;
    }

    checkForExistingConsent(
        data: any,
        cookieConsentOverlay: HTMLElement | null,
        cookieConsentForm: HTMLElement | null
    ): void {
        CookieConsent.trackAnalytics(data);

        if (!data.HasIndicatedConsent && !cookieConsentForm) {
            if (cookieConsentOverlay) {
                // eslint-disable-next-line no-param-reassign
                cookieConsentOverlay.style.display = 'block';
                if (
                    cookieConsentOverlay.classList.contains(
                        'gw-cookie-consent--overlay'
                    )
                ) {
                    document.body.classList.add('has-open-modal');
                }
            }
        }

        this.fetchConsentedScripts(data.ContentUrl);
    }

    fetchConsentedScripts(contentUrl: string): void {
        const ajaxUrl = contentUrl;
        const { ajaxContainer, showAjaxLoader, ajaxTimeout } = this;

        CookieConsent.fetchScripts(
            ajaxUrl,
            ajaxContainer,
            showAjaxLoader,
            ajaxTimeout
        );
    }

    acceptAllClickHandler(e: MouseEvent): void {
        e.preventDefault();

        const settings = {
            PerformanceEnabled: true,
            FunctionalityEnabled: true,
            TargetingEnabled: true,
            HasIndicatedConsent: true,
        };

        const ajaxUrl = '/Consent';
        const ajaxMethod = 'POST';
        const { ajaxTimeout, cookieConsentOverlay } = this;
        const ajaxBody = settings;

        fetch(ajaxUrl, {
            method: ajaxMethod,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(ajaxBody),
            signal: ajaxAbortHandler({
                ajaxTimeout,
            }),
        })
            .then((response) => response.json())
            .then(() => {
                if (cookieConsentOverlay) {
                    cookieConsentOverlay.style.display = 'none';
                }
                document.location.reload();
            })
            .catch((error) => {
                ajaxErrorHandler({ error });
            });
    }

    rejectAllClickHandler(e: MouseEvent): void {
        e.preventDefault();

        const settings = {
            PerformanceEnabled: false,
            FunctionalityEnabled: false,
            TargetingEnabled: false,
            HasIndicatedConsent: true,
        };

        const ajaxUrl = '/Consent';
        const ajaxMethod = 'POST';
        const { ajaxTimeout, cookieConsentOverlay } = this;
        const ajaxBody = settings;

        fetch(ajaxUrl, {
            method: ajaxMethod,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(ajaxBody),
            signal: ajaxAbortHandler({
                ajaxTimeout,
            }),
        })
            .then((response) => response.json())
            .then(() => {
                if (cookieConsentOverlay) {
                    cookieConsentOverlay.style.display = 'none';
                }
                document.location.reload();
            })
            .catch((error) => {
                ajaxErrorHandler({ error });
            });
    }

    // This method is quite long, but self-contained, so no point splitting out into separate class methods.
    manageConsent(): void {
        function getConsentSetting(id: string): boolean {
            const elem = document.getElementById(id) as HTMLInputElement;
            return elem.checked;
        }

        function setConsentSetting(id: string, value: boolean): void {
            const elem = document.getElementById(id) as HTMLInputElement;

            if (elem) {
                elem.checked = value;
            }
        }

        function updateUserInterface(data: any): void {
            setConsentSetting('cb-performance', data.PerformanceEnabled);
            setConsentSetting('cb-functionality', data.FunctionalityEnabled);
            setConsentSetting('cb-targeting', data.TargetingEnabled);
        }

        function saveAndReturnToReferrer(): void {
            const { referrer } = document;
            // eslint-disable-next-line no-restricted-globals
            if (referrer.indexOf(location.hostname) > -1) {
                document.location.href = document.referrer;
            } else {
                document.location.href = '/';
            }
        }

        // Update UI (checkbox 'checked' states) with current cookie settings.
        let ajaxUrl = '/Consent';
        let ajaxMethod = 'GET';
        const { ajaxTimeout } = this;

        fetch(ajaxUrl, {
            method: ajaxMethod,
            headers: {
                'Content-Type': 'application/json',
            },
            signal: ajaxAbortHandler({
                ajaxTimeout,
            }),
        })
            .then((response) => response.json())
            .then((json) => {
                updateUserInterface(json);
            })
            .catch((error) => {
                ajaxErrorHandler({ error });
            });

        // Save new preferemces.
        this.btnSavePreferences?.addEventListener('click', () => {
            const settings = {
                PerformanceEnabled: getConsentSetting('cb-performance'),
                FunctionalityEnabled: getConsentSetting('cb-functionality'),
                TargetingEnabled: getConsentSetting('cb-targeting'),
                HasIndicatedConsent: true,
            };

            ajaxUrl = '/Consent';
            ajaxMethod = 'POST';
            const ajaxBody = settings;

            fetch(ajaxUrl, {
                method: ajaxMethod,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(ajaxBody),
                signal: ajaxAbortHandler({
                    ajaxTimeout,
                }),
            })
                .then((response) => response.json())
                .then(() => {
                    saveAndReturnToReferrer();
                })
                .catch((error) => {
                    ajaxErrorHandler({ error });
                });
        });
    }

    setAllCookies(): void {
        const ajaxUrl =
            '/Content/Consent/Performance/Functionality/Targeting?isSilentAccept=true';
        const { ajaxContainer, showAjaxLoader, ajaxTimeout } = this;

        CookieConsent.fetchScripts(
            ajaxUrl,
            ajaxContainer,
            showAjaxLoader,
            ajaxTimeout
        );
    }

    setCustomCookies(): void {
        const ajaxUrl = '/Consent';
        const ajaxMethod = 'GET';
        const { cookieConsentOverlay, cookieConsentForm, ajaxTimeout } = this;

        fetch(ajaxUrl, {
            method: ajaxMethod,
            headers: {
                'Content-Type': 'application/json',
            },
            signal: ajaxAbortHandler({
                ajaxTimeout,
            }),
        })
            .then((response) => response.json())
            .then((json) => {
                this.checkForExistingConsent(
                    json,
                    cookieConsentOverlay,
                    cookieConsentForm
                );
            })
            .catch((error) => {
                ajaxErrorHandler({ error });
            });
    }

    setCookies(allCookies: boolean): void {
        if (allCookies) {
            this.setAllCookies();
        } else {
            // Set custom cookies if we're not in the CMS or Storybook/Chromatic.
            document.location.href.indexOf('geronimo-cms') < 0 &&
                document.location.href.indexOf('localhost:9009') < 0 &&
                document.location.href.indexOf('chromatic') < 0 &&
                this.setCustomCookies();
        }

        // Click 'accept all' button in cookie consent overlay.
        if (this.btnCookieAccept) {
            this.btnCookieAccept.addEventListener('click', (e: MouseEvent) => {
                this.acceptAllClickHandler(e);
            });
        }

        if (this.btnCookieReject) {
            this.btnCookieReject.addEventListener('click', (e: MouseEvent) => {
                this.rejectAllClickHandler(e);
            });
        }

        // Manage consent form.
        if (this.cookieConsentForm) {
            this.manageConsent();
        }
    }
}
