export class FinanceOptions {
    private toggleButtons: HTMLElement | null;

    constructor(toggleButtons: HTMLElement) {
        this.toggleButtons = toggleButtons;
        this.init();
    }

    public static start(): any {
        const toggleButtons = document.querySelector<HTMLElement>(
            '.gw-button-group--toggle__payment-options'
        );
        if (toggleButtons) {
            const instance = new FinanceOptions(toggleButtons);
            return instance;
        }
    }

    private init(): void {
        this.handleToggleButtons();
    }

    //Handles changing the selected class for which finance option is selected
    private handleToggleButtons(): void {
        // Find the both buttons within the toggle group for payment type
        const priceButton = this.toggleButtons?.querySelector(
            '.gw-button--toggle__price'
        );

        const financeButton = this.toggleButtons?.querySelector(
            '.gw-button--toggle__finance'
        );

        const financeInfo = document.querySelector(
            '.gw-payment-options__finance'
        );

        if (financeInfo) {
            financeInfo.classList.add('is-hidden');
        }

        // If buttons exist, find the price panels on the right hand side
        if (priceButton && financeButton) {
            document
                .querySelectorAll('.gw-product-summary')
                .forEach((productSummary) => {
                    const pricePanel = productSummary?.querySelector(
                        '.gw-product-summary__price'
                    );
                    const financePanel = productSummary?.querySelector(
                        '.gw-product-summary__finance'
                    );

                    if (priceButton.classList.contains('is-selected')) {
                        pricePanel?.classList.add('is-selected');
                    }

                    if (financeButton.classList.contains('is-selected')) {
                        financePanel?.classList.add('is-selected');
                    }

                    // Add an event listener for when the price button is clicked and remove selected class from finance button and on the finance section of the panel
                    priceButton.addEventListener('click', (e) => {
                        pricePanel?.classList.add('is-selected');
                        priceButton.classList.add('is-selected');
                        if (financePanel?.classList.contains('is-selected')) {
                            financePanel?.classList.remove('is-selected');
                            financeButton.classList.remove('is-selected');
                        }

                        if (!financeInfo?.classList.contains('is-hidden')) {
                            financeInfo?.classList.add('is-hidden');
                        }
                    });

                    // Add an event listener for when the finance button is clicked and remove selected class from price button and on the price section of the panel
                    financeButton.addEventListener('click', (e) => {
                        if (pricePanel?.classList.contains('is-selected')) {
                            pricePanel?.classList.remove('is-selected');
                            priceButton.classList.remove('is-selected');
                        }
                        financePanel?.classList.add('is-selected');
                        financeButton.classList.add('is-selected');

                        if (financeInfo?.classList.contains('is-hidden')) {
                            financeInfo.classList.remove('is-hidden');
                        }
                    });
                });
        }
    }
}
