import { addJSClass } from '../utils/progressive-enhancement';

export class MultiToggle {
    private multiToggle: HTMLElement;
    private fields: HTMLElement[];

    constructor(multiToggle: HTMLElement) {
        this.multiToggle = multiToggle;
        this.fields = Array.from(
            multiToggle.querySelectorAll('.gw-form__field')
        );
        this.init();
    }

    public static start(): void {
        const multiToggles = document.querySelectorAll(
            '.gw-button--multi-toggle'
        );
        if (multiToggles) {
            [...(multiToggles as any)].map((toggle) => {
                addJSClass(toggle);
                const instance = new MultiToggle(toggle);
                return instance;
            });
        }
    }

    private init(): void {
        this.handleToggle();
    }

    private handleToggle() {
        this.fields.forEach((field) => {
            field.addEventListener('click', () => {
                this.fields.forEach((field) => {
                    field.classList.remove('is-selected');
                });
                field.classList.add('is-selected');
            });
        });
    }
}
