type MinMaxFieldset = {
    min: HTMLSelectElement;
    max: HTMLSelectElement;
};

export class FormMinmax {
    private fields: NodeListOf<HTMLSelectElement>;
    private minMaxFieldsets: MinMaxFieldset[] = [];
    private multiToggle: Element | null = null;

    /**
     * Populates class properties.
     * @param formMinmax The minmax element to be constructed
     */
    constructor(private formMinmax: Element) {
        const fields = formMinmax.querySelectorAll(
            '.gw-form__select'
        ) as NodeListOf<HTMLSelectElement>;
        this.fields = fields as NodeListOf<HTMLSelectElement>;
        for (let i = 0; i < fields.length; i += 2) {
            this.minMaxFieldsets.push({
                min: fields[i],
                max: fields[i + 1],
            });
        }
        this.multiToggle = formMinmax.querySelector('.gw-button--multi-toggle');
        this.init();
    }

    public static start(): void {
        const formMinMaxList = document.querySelectorAll(
            '.gw-form__fieldset--minmax'
        );

        formMinMaxList.forEach((minMax) => {
            return new FormMinmax(minMax);
        });
    }

    private init(): void {
        this.initDropdownValuesDisplay();
        if (this.multiToggle) {
            this.handleUnitToggle();
            this.minMaxFieldsets.forEach((fieldset) => {
                this.resetHiddenFields(fieldset.min);
                this.resetHiddenFields(fieldset.max);
            });
        }
    }

    /**
     * Initialises state of min/max values and handles when values are changed.
     */
    private initDropdownValuesDisplay() {
        this.minMaxFieldsets.forEach((fieldset) => {
            this.hideMaxOptionsLessThanMin(fieldset.min, fieldset.max);
            this.hideMinOptionsGreaterThanMax(fieldset.min, fieldset.max);

            fieldset.min.addEventListener('change', () => {
                this.hideMaxOptionsLessThanMin(fieldset.min, fieldset.max);
            });

            fieldset.max.addEventListener('change', () => {
                this.hideMinOptionsGreaterThanMax(fieldset.min, fieldset.max);
            });
        });
    }

    /**
     * In a min/max pair, hide all min options greater than the selected Max value
     * @param min The Min field
     * @param max The Max field
     */
    private hideMinOptionsGreaterThanMax(
        min: HTMLSelectElement,
        max: HTMLSelectElement
    ) {
        for (let i = 1; i < max.length; i++) {
            if (max.selectedIndex > 0 && i >= max.selectedIndex) {
                min[i].style.display = 'none';
            } else {
                min[i].style.display = 'block';
            }
        }
    }

    /**
     * In a min/max pair, hide all max options less than the selected Min value
     * @param min The Min field
     * @param max The Max field
     */
    private hideMaxOptionsLessThanMin(
        min: HTMLSelectElement,
        max: HTMLSelectElement
    ) {
        for (let i = 1; i < min.length; i++) {
            if (min.selectedIndex > 0 && i <= min.selectedIndex) {
                max[i].style.display = 'none';
            } else {
                max[i].style.display = 'block';
            }
        }
    }

    /**
     * If multi-toggle has more than 1 option, display the correct information corresponding to the selected option.
     */
    private handleUnitToggle() {
        const toggleInputs = Array.from(
            this.multiToggle!.querySelectorAll('.gw-form__input[type=radio]')
        ) as HTMLInputElement[];

        if (toggleInputs.length > 1) {
            const toggleFields = [] as HTMLElement[][];
            for (let i = 0; i < this.fields.length; i += 2) {
                toggleFields.push([this.fields[i], this.fields[i + 1]]);
            }

            this.selectMeasurement(toggleInputs, toggleFields);

            this.formMinmax.addEventListener('change', () => {
                this.selectMeasurement(toggleInputs, toggleFields);
            });
        } else if (toggleInputs.length === 1) {
            this.multiToggle!.classList.add('is-hidden');
        }
    }

    /**
     * Displays fieldset corresponding to selected multi-toggle option.
     * @param toggleInputs Array of multi-toggle options
     * @param toggleFields Array of fieldsets
     */
    private selectMeasurement(
        toggleInputs: HTMLInputElement[],
        toggleFields: HTMLElement[][]
    ) {
        this.fields.forEach((field) => {
            if (field.closest('.gw-form__field')) {
                field.closest('.gw-form__field')!.classList.add('is-hidden');
            } else field.classList.add('is-hidden');
        });

        const selectedIndex = toggleInputs.findIndex(
            (toggle) => toggle.checked
        );
        toggleFields[selectedIndex].forEach((field) => {
            if (field.closest('.gw-form__field')) {
                field.closest('.gw-form__field')!.classList.remove('is-hidden');
            } else {
                field.classList.remove('is-hidden');
            }
        });
    }

    /**
     * When a field is changed, resets values of all hidden min/max fieldsets to default.
     * @param field
     */
    private resetHiddenFields(field: HTMLSelectElement) {
        field.addEventListener('change', () => {
            const hiddenFields = this.formMinmax.querySelectorAll(
                '.gw-form__field.is-hidden select'
            ) as NodeListOf<HTMLSelectElement>;
            hiddenFields.forEach((hiddenField) => {
                hiddenField.selectedIndex = 0;
                this.resetFieldOptions(hiddenField);
            });
        });
    }

    private resetFieldOptions(field: HTMLSelectElement): void {
        for (let i = 0; i < field.length; i++) {
            field[i].style.display = 'block';
        }
    }
}
