import { addJSClass } from '../utils/progressive-enhancement';
import { getEventElement } from '../utils/event-handlers';
import {
    updateQueryStringParameter,
    removeQueryStringParameter,
} from '../utils/query-strings';

export class SearchFilters {
    private searchFiltersContainer: HTMLElement;
    private searchFilterContentsContainer: HTMLElement;
    private searchFilters: HTMLElement | null;
    private searchForm: HTMLFormElement | null;
    private searchCondition: HTMLSelectElement | null;
    private searchDisplayCondition: HTMLSelectElement | null;
    private searchSortSelect: HTMLSelectElement | null;
    private searchSortButton: HTMLButtonElement | null;
    private btnReset: HTMLButtonElement | null;

    constructor(
        searchFiltersContainer: HTMLElement,
        searchFilterContentsContainer: HTMLElement
    ) {
        this.searchFiltersContainer = searchFiltersContainer;
        this.searchFilterContentsContainer = searchFilterContentsContainer;
        this.searchFilters = document.querySelector(
            '[data-id=products-filter-content]'
        );
        this.searchForm = document.querySelector(
            '.gw-product-search-results__form'
        );
        this.searchCondition = document.querySelector('#Condition');
        this.searchDisplayCondition =
            document.querySelector('#DisplayCondition');
        this.searchSortSelect = document.querySelector(
            '[data-id=products-sort-select]'
        );
        this.searchSortButton = document.querySelector(
            '[data-id=products-sort-button]'
        );
        this.btnReset = document.querySelector('[type=reset]');

        this.init();
    }

    public static start(): any {
        const searchFiltersContainer = document.querySelector(
            '.gw-product-search-results'
        ) as HTMLElement;
        const searchFilterContentsContainer = document.querySelector(
            '.gw-product-search-content'
        ) as HTMLElement;

        if (searchFiltersContainer) {
            addJSClass(searchFiltersContainer);
            addJSClass(searchFilterContentsContainer);

            const instance = new SearchFilters(
                searchFiltersContainer,
                searchFilterContentsContainer
            );

            return instance;
        }
    }

    private init(): void {
        this.initSearchFilters();
        this.handleFiltersScroll();
        this.handleSort();
        this.handleStickyHeader();
        this.handleReset();
    }

    private initSearchFilters(): void {
        // Search conditions.
        if (this.searchCondition) {
            this.searchCondition.addEventListener('change', (e) => {
                const target = getEventElement(e.target) as HTMLSelectElement;

                target && this.handleSearchCondition(target.value, target.id);
            });
        }

        // Search display conditions.
        if (this.searchDisplayCondition) {
            this.searchDisplayCondition.addEventListener('change', (e) => {
                const target = getEventElement(e.target) as HTMLSelectElement;

                target && this.handleSearchCondition(target.value, target.id);
            });
        }
    }

    private handleFiltersScroll(): void {
        if (this.searchFiltersContainer.querySelector('.gw-product-filter')) {
            const searchFilters = this.searchFiltersContainer.querySelector(
                '.gw-product-filter'
            ) as HTMLElement;
            const searchFiltersTabContents =
                searchFilters.querySelectorAll('.gw-tabs__content');

            const scrollCallback = (): void => {
                [...(searchFiltersTabContents as any)].map(
                    (searchFiltersTabContent) => {
                        searchFiltersTabContent.style.marginBlockStart = `${searchFilters?.scrollTop}px`;

                        return true;
                    }
                );
            };
            window.setTimeout(() => {
                searchFilters?.addEventListener('scroll', scrollCallback);
            }, 500);
        }
    }

    private handleSearchCondition(condition: string, id: string): void {
        let url = window.location.href;

        url = url.replace(/\/[0-9]+(?=\?)|\/[0-9]+$/g, '');

        if (condition === '0' || condition === '') {
            url = removeQueryStringParameter(url, id);
        } else {
            url = updateQueryStringParameter(url, id, condition);
        }

        window.location.href = url;
    }

    /**
     * When Sort By option is changed, redirects URL with updated sorting parameters
     */
    private handleSort(): void {
        // Sort search results.
        if (this.searchSortSelect && this.searchSortButton && this.searchForm) {
            // Hide sorting GO button.
            this.searchSortButton.style.display = 'none';

            this.searchSortSelect.addEventListener('change', () => {
                // Submit sort form.
                const url = window.location.href;
                const selectedIndex = this.searchSortSelect!.selectedIndex;
                const sortParams = (
                    this.searchSortSelect![selectedIndex] as HTMLOptionElement
                ).value.split('_');
                const sortType = sortParams[0];
                const sortDir = sortParams[1];
                const sortedUrl =
                    rebuildUrl(url) + 'Sort=' + sortType + '&Dir=' + sortDir;
                window.location.href = sortedUrl;

                function rebuildUrl(url: string) {
                    const baseUrl = url.split('?')[0];
                    const queryUrl = url.split('?')[1];
                    let newQueryUrl = '';

                    if (queryUrl !== undefined) {
                        queryUrl.split('&').forEach((query) => {
                            if (
                                query.indexOf('Sort') < 0 &&
                                query.indexOf('Dir') < 0
                            ) {
                                newQueryUrl = newQueryUrl + query + '&';
                            }
                        });
                    }

                    return baseUrl + '?' + newQueryUrl;
                }
            });
        }
    }

    /**
     * Ensures sticky header doesn't overlap with sticky product search results.
     * If window is scrolled past sticky header breakpoint, stack product search results under sticky header.
     * If window is resized, adjust according to new sticky header size.
     */
    private handleStickyHeader() {
        const stickyHeader = document.querySelector(
            '.gw-layout__header'
        ) as HTMLElement;
        if (stickyHeader) {
            document.addEventListener('scroll', () => {
                if (stickyHeader.classList.contains('is-js-sticky')) {
                    this.searchFiltersContainer.style.top =
                        this.getStickyHeaderOffset(stickyHeader);
                } else {
                    this.searchFiltersContainer.style.top = '0';
                }
            });
            window.addEventListener('resize', () => {
                this.searchFiltersContainer.style.top =
                    this.getStickyHeaderOffset(stickyHeader);
            });
        }
    }

    /**
     * Returns string value of sticky header height (in px).
     * @param stickyHeader Sticky header element
     * @returns String value in px of the sticky header height.
     */
    private getStickyHeaderOffset(stickyHeader: HTMLElement) {
        const headerHeight = stickyHeader.offsetHeight - 1;
        const heightString = headerHeight.toString() + 'px';
        return heightString;
    }

    private handleReset(): void {
        // Reset search results.
        if (this.btnReset) {
            this.btnReset.addEventListener('click', (e) => {
                const target = getEventElement(e.target) as HTMLButtonElement;

                if (target && this.searchFilters) {
                    const checkboxes =
                        this.searchFilters.querySelectorAll('[type=checkbox]');
                    const slidersMin =
                        this.searchFilters.querySelectorAll('[data-range=min]');
                    const slidersMax =
                        this.searchFilters.querySelectorAll('[data-range=max]');

                    [...(checkboxes as any)].map((checkbox) => {
                        if (checkbox.checked) {
                            checkbox.click();
                        }

                        return true;
                    });

                    [...(slidersMin as any)].map((sliderMin) => {
                        const output = sliderMin.nextElementSibling;

                        if (sliderMin.value !== sliderMin.min && output) {
                            // eslint-disable-next-line no-param-reassign
                            sliderMin.value = sliderMin.min;
                            output.textContent = sliderMin.min;
                        }

                        return true;
                    });

                    [...(slidersMax as any)].map((sliderMax) => {
                        const output = sliderMax.nextElementSibling;

                        if (sliderMax.value !== sliderMax.max && output) {
                            // eslint-disable-next-line no-param-reassign
                            sliderMax.value = sliderMax.max;
                            output.textContent = sliderMax.max;
                        }

                        return true;
                    });
                }
            });
        }
    }
}
