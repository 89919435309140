import { addJSClass } from '../utils/progressive-enhancement';
import { ajaxAbortHandler, ajaxErrorHandler } from '../utils/ajax-helpers';

interface MakeModelObject {
    Make: string;
    Models: string[];
}

export class StockFilter {
    private isFilterSidebar: boolean;
    private makeFilter: Element;
    private makeFilterContent: Element | null;
    private makeFilterInputs: HTMLElement[];
    private modelFilter: Element;
    private modelFilterContent: Element | null;
    private isLocalServer: boolean;

    constructor(private stockFilter: Element) {
        addJSClass(stockFilter);
        const wrapper = this.stockFilter.closest('form') as Element;
        this.isFilterSidebar = wrapper.closest('.gw-filter-sidebar') != null;
        this.makeFilter = wrapper.querySelector("[data-id='make']") as Element;
        this.makeFilterContent = wrapper.querySelector(
            "[data-id='make-content']"
        );
        this.makeFilterInputs = Array.from(
            !this.isFilterSidebar
                ? wrapper.querySelectorAll("[dataId='make-content']")
                : wrapper.querySelectorAll(
                      "[data-id='make-content'] .gw-form__input"
                  )
        );
        this.modelFilter = wrapper.querySelector(
            "[data-id='model']"
        ) as Element;
        this.modelFilterContent = wrapper.querySelector(
            "[data-id='model-content']"
        );
        this.isLocalServer = window.location.href.includes('localhost');
        this.init();
    }

    public static start(): void {
        const stockFilters = document.querySelectorAll('.gw-stock-filter-ajax');
        stockFilters.forEach((s) => {
            addJSClass(s);
            const instance = new StockFilter(s);
            return instance;
        });
    }

    private init(): void {
        let url = this.isLocalServer ? '/ajax/stock-filter' : this.buildUrl();
        this.ajaxFetch(url, true);
        this.handleFilterDisplay();
        this.makeFilterInputs.forEach((input) => {
            input.addEventListener('change', () => {
                if (!this.isLocalServer) url = this.buildUrl();
                this.ajaxFetch(url);
                this.handleFilterDisplay();
            });
        });
    }

    /**
     * Builds URL endpoint containing list of makes selected by the user. This endpoint will be targeted during the AJAX fetch.
     * @returns URL string for fetching model names
     */
    private buildUrl() {
        const baseUrl = '/StockFilter/FilteredVehicleMakeModels/';
        const checkedMakesString =
            "'" + this.getCheckedMakes().join("','") + "'";
        const url = baseUrl + '[' + checkedMakesString + ']';
        return url;
    }

    /**
     * Checks to see if Make and Model filter need to be opened or disabled on page load.
     */
    private handleFilterDisplay() {
        const isEnabledCondition = !this.isFilterSidebar
            ? (this.makeFilterInputs[0] as HTMLSelectElement).selectedIndex > 0
            : this.getCheckedMakes().length > 0;
        if (isEnabledCondition) {
            this.enableModelDropdown();
            if (this.isFilterSidebar) {
                this.showFilter(this.makeFilter, this.makeFilterContent);
                this.showFilter(this.modelFilter, this.modelFilterContent);
            }
        } else {
            this.disableModelDropdown();
        }
    }

    /**
     * Returns array of all makes selected by user in dropdown boxes.
     * @returns String array of all Make checkboxes selected by user.
     */
    private getCheckedMakes(): string[] {
        const checkedMakes = !this.isFilterSidebar
            ? Array.from(
                  (this.makeFilterInputs as HTMLSelectElement[])[0]
                      .selectedOptions
              )
            : (this.makeFilterInputs as HTMLInputElement[]).filter(
                  (c) => c.checked
              );
        const checkedValues = checkedMakes.map((m) => m.value);
        return checkedValues;
    }

    /**
     * Toggles accordion label to active state and displays accordion content
     * @param label Accordion label
     * @param content Accordion content
     */
    private showFilter(label: Element, content: Element | null) {
        label.classList.add('active');
        content?.classList.add('show');
    }

    /**
     * Disables Model accordion dropdown
     */
    private disableModelDropdown() {
        this.modelFilter.classList.add('is-disabled');
        this.modelFilter.classList.remove('active');
        this.modelFilterContent?.classList.remove('show');
        if (!this.isFilterSidebar) {
            (this.stockFilter as HTMLSelectElement).disabled = true;
        }
    }

    /**
     * Enables Model accordion dropdown
     */
    private enableModelDropdown() {
        this.modelFilter.classList.remove('is-disabled');
        if (!this.isFilterSidebar) {
            (this.stockFilter as HTMLSelectElement).disabled = false;
        }
    }

    /**
     * Performs Ajax fetch from a supplied endpoint and displays results in Model dropdown.
     * @param url The URL endpoint to fetch from.
     * @param isOnPageLoad True if the fetch has been called upon page load (not via an event listener);
     */
    private ajaxFetch(url: string, isOnPageLoad = false): void {
        const ajaxLoaderContainer = this.stockFilter as HTMLElement;
        const ajaxContainer = ajaxLoaderContainer;
        const showAjaxLoader = true;
        const ajaxTimeout = 10000;
        fetch(url, {
            signal: ajaxAbortHandler({
                ajaxLoaderContainer,
                showAjaxLoader,
                ajaxTimeout,
            }),
        })
            .then((response) => response.text())
            .then((text) => {
                const checkedModels = !isOnPageLoad
                    ? this.getSelectedModels()
                    : [];
                const makeModelsObjects = JSON.parse(text) as MakeModelObject[];
                let htmlString = ``;
                if (!this.isFilterSidebar) {
                    const modelDropdown =
                        this.modelFilter.getElementsByTagName('option')[0];
                    htmlString += `${modelDropdown.outerHTML}`;
                }
                makeModelsObjects.forEach((object) => {
                    if (!this.isFilterSidebar) {
                        object.Models.forEach((model) => {
                            htmlString += `<option value='${model}'>${model}</option>`;
                        });
                    } else {
                        htmlString += `<label class='gw-form__label'><h5>${object.Make}</h5></label>`;
                        object.Models.forEach((model) => {
                            const checkedString = checkedModels.includes(model)
                                ? 'checked'
                                : '';
                            htmlString += `<div class="gw-form__field gw-form__field--checkbox"><label class='gw-form__label'><span>${model}</span><input class='gw-form__input' type='checkbox' name='Filters.ModelName' value='${model}' ${checkedString} /></label></div>`;
                        });
                    }
                });
                ajaxContainer.innerHTML = htmlString;
            })
            .then(() => {
                isOnPageLoad &&
                    this.isFilterSidebar &&
                    this.populateSelectedModelsOnPageLoad();
            })
            .catch((error) => {
                ajaxErrorHandler({
                    error,
                    ajaxContainer,
                });
            });
    }

    /**
     * If user submits models, populate the next page load with those models.
     */
    private populateSelectedModelsOnPageLoad() {
        const localUrl =
            '?Dir=0&ModelName=Fiesta%201.0%20EcoB%20125%20Titanium&ModelName=Ranger&ModelName=Estima&';
        const url = this.isLocalServer ? localUrl : window.location.href;
        const modelsInUrl = url.match(/ModelName=.*?&/g);
        if (modelsInUrl) {
            const modelCheckboxes = this.stockFilter.querySelectorAll(
                '.gw-form__input'
            ) as NodeListOf<HTMLInputElement>;
            modelsInUrl.forEach((str) => {
                str = str.replace('ModelName=', '');
                str = str.substring(0, str.length - 1);
                str = str.replace(/%20/g, ' ');
                modelCheckboxes.forEach((checkbox) => {
                    if (checkbox.value === str) {
                        checkbox.checked = true;
                    }
                });
            });
        }
    }

    private getSelectedModels() {
        const modelInputs = Array.from(
            this.stockFilter.querySelectorAll(
                '.gw-form__field--checkbox .gw-form__input'
            )
        ) as HTMLInputElement[];
        const checkedModels = modelInputs.filter((c) => c.checked);
        return checkedModels.map((c) => c.value);
    }
}
