/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck so we don't get errors for ts(2367).

// Dynamically import & instantiate components based on current theme.
import { currentUITheme } from './ui-current-theme';

export const gwUIinitTheme = (): void => {
    if (currentUITheme === 'whitelabel') {
        // No dynamic imports for whitelabel.
    }

    // Check for FDS theme.
    if (currentUITheme === 'FMLDS') {
        // No dynamic imports for FMLDS.
    }

    if (currentUITheme === 'HDS') {
        // No dynamic imports for HDS.
    }

    if (currentUITheme === 'LDS') {
        // No dynamic imports for LDS.
    }

    if (
        currentUITheme === 'multi-franchise' ||
        currentUITheme === 'LIDS' ||
        currentUITheme === 'LODS' ||
        currentUITheme === 'DDS' ||
        currentUITheme === 'CARDS' ||
        currentUITheme === 'HEDDS'
    ) {
        // No dynamic imports for MF.
    }
};
