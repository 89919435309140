import { addJSClass } from '../utils/progressive-enhancement';

export class TabbedSlider {
    private tabBtns: NodeListOf<HTMLElement> | null;

    constructor(private tabbedSlider: Element) {
        this.tabBtns = this.tabbedSlider?.querySelectorAll(
            '.gw-tabbed-slider__button'
        );

        this.init();
    }

    public static start(): any {
        const tabbedSlider =
            document.querySelector<HTMLElement>('.gw-tabbed-slider');
        if (tabbedSlider) {
            addJSClass(tabbedSlider);
            const instance = new TabbedSlider(tabbedSlider);
            return instance;
        }
    }

    private init(): void {
        this.initTabbedSlider();
        this.handleTabClick();
    }

    private initTabbedSlider(): void {
        if (this.tabBtns) {
            this.tabBtns.forEach((btn) => {
                btn.setAttribute('tabindex', '0');
                this.handleTabClick();

                btn.addEventListener('keydown', (e) => {
                    const keyboardEvent = e as KeyboardEvent;
                    if (
                        keyboardEvent.code == 'Enter' &&
                        !btn.classList.contains('is-selected')
                    ) {
                        btn.querySelector<HTMLElement>(
                            '.gw-tabbed-slider__input'
                        )?.click();
                        this.handleTabClick();
                    }
                });

                btn.addEventListener('click', () => {
                    this.handleTabClick();
                });
            });
        }
    }

    private handleTabClick(): void {
        const tabInputs = document.querySelectorAll(
            '.gw-tabbed-slider__input[name="tabbed-slider"]'
        );

        tabInputs.forEach((input) => {
            const dataId = input.getAttribute('data-id');
            const closestBtn = input.closest('.gw-tabbed-slider__button');

            if (dataId) {
                const image = this.tabbedSlider?.querySelector(
                    `.gw-tabbed-slider__image[data-id="${dataId}"]`
                );

                if ((input as HTMLInputElement).checked) {
                    image?.classList.add('is-selected');
                    closestBtn?.classList.add('is-selected');
                } else {
                    image?.classList.remove('is-selected');
                    closestBtn?.classList.remove('is-selected');
                }
            }
        });
    }
}
