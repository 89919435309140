export const triggerInterceptOldForms = (): void => {
    if (typeof URLSearchParams === 'undefined') {
        return;
    }

    const urlParams = new URLSearchParams(window.location.search);

    const myParam = urlParams.get('of');

    if (myParam) {
        document.location = myParam as any;
    }
};
