export class StickyPosition {
    private stickyHeader: HTMLElement;
    private stickyFooter: HTMLElement;
    private main: HTMLElement;
    private headerOverlay: HTMLElement;
    private fullWidthModal: HTMLElement;
    private dropdown: HTMLElement;
    private overlay: HTMLElement;

    constructor() {
        this.stickyHeader = document.querySelector(
            '.gw-layout__sticky--header'
        ) as HTMLElement;
        this.stickyFooter = document.querySelector(
            '.gw-layout__sticky--footer'
        ) as HTMLElement;
        this.main = document.querySelector('.gw-layout__main') as HTMLElement;
        this.headerOverlay = document.getElementById(
            'header-overlay'
        ) as HTMLElement;
        this.fullWidthModal = document.querySelector(
            '.gw-modal--full-width'
        ) as HTMLElement;

        this.init();
    }

    public static start(): any {
        const instance = new StickyPosition();

        return instance;
    }

    private init(): void {
        this.initSticky();
    }

    private initSticky(): void {
        if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver(
                // Callback.
                (entries) => {
                    entries.forEach((entry) => {
                        if (this.stickyHeader || this.stickyFooter) {
                            if (entry.isIntersecting) {
                                this.stickyHeader?.classList.add(
                                    'is-js-sticky'
                                );
                                this.stickyFooter?.classList.add(
                                    'is-js-sticky'
                                );

                                // Check for the full-width modal component
                                if (this.fullWidthModal) {
                                    this.pushModalUp(
                                        this.stickyFooter,
                                        this.fullWidthModal
                                    );
                                }

                                // Remove inline style so that header overlay is correctly positioned by CSS, not by JS.
                                if (
                                    this.stickyHeader?.classList.contains(
                                        'has-nav-overflow'
                                    )
                                ) {
                                    this.headerOverlay?.removeAttribute(
                                        'style'
                                    );
                                }
                            } else {
                                this.stickyHeader?.classList.remove(
                                    'is-js-sticky'
                                );
                                this.stickyFooter?.classList.remove(
                                    'is-js-sticky'
                                );

                                // Check for the full-width modal component
                                if (this.fullWidthModal) {
                                    this.pushModalDown(this.fullWidthModal);
                                }
                            }
                        }
                    });
                },
                // Options.
                {
                    // The -100% is the important bit, allowing <main> element to start scrolling out of view to trigger the observer callback.
                    rootMargin: '0px 0px -100%',
                }
            );

            if (this.main) {
                observer.observe(this.main);
            }
        }
    }

    private pushModalUp(
        stickyFooter: HTMLElement | null,
        modal: HTMLElement
    ): void {
        if (stickyFooter) {
            const elemHtml = document.documentElement;
            const documentWidth = elemHtml.clientWidth;
            const windowWidth = window.innerWidth;
            const scrollWidth = windowWidth - documentWidth;

            const dropdown = document.querySelector(
                '.gw-branch-rotator__dropdown'
            ) as HTMLElement;
            const overlay = document.querySelector(
                '.gw-layout__header__overlay'
            ) as HTMLElement;

            if (document.body.classList.contains('has-open-modal')) {
                modal.style.insetInlineEnd = `${scrollWidth}px`;
                if (overlay) overlay.style.insetInlineEnd = `${scrollWidth}px`;
                if (dropdown)
                    dropdown.style.insetInlineEnd = `${scrollWidth}px`;
            }

            let elementHeight = stickyFooter.offsetHeight.toString();
            elementHeight += 'px';
            modal.style.insetBlockEnd = elementHeight;
        }
    }

    private pushModalDown(modal: HTMLElement): void {
        const elemHtml = document.documentElement;
        const documentWidth = elemHtml.clientWidth;
        const windowWidth = window.innerWidth;
        const scrollWidth = windowWidth - documentWidth;

        const dropdown = document.querySelector(
            '.gw-branch-rotator__dropdown'
        ) as HTMLElement;
        const overlay = document.querySelector(
            '.gw-layout__header__overlay'
        ) as HTMLElement;

        if (document.body.classList.contains('has-open-modal')) {
            modal.style.insetInlineEnd = `${scrollWidth}px`;
            if (overlay) overlay.style.insetInlineEnd = `${scrollWidth}px`;
            if (dropdown) dropdown.style.insetInlineEnd = `${scrollWidth}px`;
        }

        modal.style.insetBlockEnd = '0';
    }
}
